@import "~tailwindcss/base.css";

@import "~tailwindcss/components.css";

@import "~tailwindcss/utilities.css";

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

@import "~@fortawesome/fontawesome-free/css/all.css";

[v-cloak] {
    display: none;
}

.blinking {
    animation: blinker 3s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.container {
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.header-polygon {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
}

/*
|----------------------------------------------------------------------------------------------------------------------|
| Buttons
|----------------------------------------------------------------------------------------------------------------------|
*/

.btn {
    @apply border transition ease-in-out duration-150;
}

.btn-lg {
    @apply h-12;
}

.btn-hover {
    @apply shadow-2xl;
}

.btn-focus {
    @apply shadow-2xl outline-none;
}

.btn-active {
    @apply shadow-2xl;
}

.btn-disabled {
    @apply btn text-neutral bg-white border border-neutral cursor-default;
}

.btn-primary {
    @apply btn bg-primary text-white border-primary;
}
/*
.btn-primary:hover {
    @apply btn-hover bg-white text-primary ;
}*/

.btn-primary-inverse {
    @apply btn bg-white text-primary border-primary;
}

.btn-primary-inverse:hover {
    @apply btn-hover bg-primary text-white;
}

.btn-danger {
    @apply btn bg-danger text-white border-danger cursor-pointer;
}

.btn-danger:hover {
    @apply btn-hover bg-white text-danger;
}

.btn-danger-inverse {
    @apply btn bg-white text-danger border-danger;
}

.btn-danger-inverse:hover {
    @apply btn-hover bg-danger text-white;
}
